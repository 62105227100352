import type { FC, HTMLAttributes, ReactNode } from 'react';

import { cn } from '@pxui/lib/utils';

interface ListHeaderProps extends HTMLAttributes<HTMLDivElement> {
  button?: ReactNode;
  icon?: ReactNode;
  title: string;
}

const layoutClasses = 'flex justify-between items-center w-full h-10';
const spacingClasses = 'py-0.5 pr-1 pl-2.5';

const ListHeader: FC<ListHeaderProps> = ({
  title,
  button,
  icon,
  className,
  ...props
}) => (
  <div className={cn(layoutClasses, spacingClasses, className)} {...props}>
    <div className="flex items-center space-x-2">
      <span className="label-1 text-secondary">{title}</span>
      {icon && <span>{icon}</span>}
    </div>

    {button && <div>{button}</div>}
  </div>
);

export { ListHeader, type ListHeaderProps };
