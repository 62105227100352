import { type HTMLAttributes, forwardRef } from 'react';

import cn from '@pxui/lib/utils';

const AppBar = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => (
    <div
      className={cn(
        'flex surface-00 border-01-subtle justify-between items-center',
        className,
      )}
      ref={ref}
      {...props}
    >
      {children}
    </div>
  ),
);

AppBar.displayName = 'AppBar';

export default AppBar;
