import lazyLoadHelper from '@utils/lazyLoadHelper';

import vaultImageSmall from '@assets/images/apps/vault_small.png';
import vaultImage from '@assets/images/launcher/vault.png';

import { type AppConfig } from '@typing/AppConfig';

import { routes } from './routes';

export const config: AppConfig = {
  appName: 'Vault',
  description: 'Securely upload and share data',
  icon: vaultImageSmall,
  id: 'vault',
  imageSrc: vaultImage,
  navigationLinks: [],
  path: 'app/vault',
  root: lazyLoadHelper(() => import('@vault/pages/index')),
  routes,
};

export default config;
