export const isBooleanString = (str: string): boolean => {
  return str === 'true' || str === 'false';
};

// Helper function to check if string is valid JSON
export const isJson = (str: string): boolean => {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
};
