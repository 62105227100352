import { Outlet } from 'react-router-dom';

import { Toaster } from '@pxui/components/ui';

import useFetchIfNotLatestTermsConditions from '@hooks/useFetchIfNotLatestTermsConditions';

import {
  FullScreenContainer,
  Navbar,
  TermsAndConditionsModal,
} from './components';

// need to run the hook inside the authGuard hence separate component
const AppWrapper = () => {
  const { isError, isLoading, acceptedLatestTerms, setAcceptedLatestTerms } =
    useFetchIfNotLatestTermsConditions();

  return (
    <>
      <FullScreenContainer>
        <Navbar />
        {/** Hide all components to prevent the request from coming out
         *  and show the T&C modal if latest T&Cs are not accepted */}
        {acceptedLatestTerms ? (
          <Outlet />
        ) : (
          <TermsAndConditionsModal
            open={!acceptedLatestTerms}
            setAccepted={setAcceptedLatestTerms}
            isError={isError}
            isLoading={isLoading}
          />
        )}
      </FullScreenContainer>
      <Toaster />
    </>
  );
};

export default AppWrapper;
