import { type HTMLAttributes, forwardRef } from 'react';

import { buttonVariants } from '@pxui/components/ui/button';
import { ArrowDropDownIcon } from '@pxui/components/ui/icons';
import { cn } from '@pxui/lib/utils';

import type { VariantProps } from 'class-variance-authority';

interface DropdownMenuTriggerButtonProps
  extends HTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  disabled?: boolean;
  hideArrow?: boolean;
}

const iconLayoutClasses = 'w-4 h-4';
const iconSpacingClasses = 'ml-auto';
const iconColorClasses = 'text-icon-primary fill-icon-primary';
const iconDisabledClasses = 'text-icon-disabled fill-icon-disabled';

const DropdownMenuTriggerButton = forwardRef<
  HTMLButtonElement,
  DropdownMenuTriggerButtonProps
>(
  (
    { className, children, variant, disabled, hideArrow = false, ...props },
    ref,
  ) => (
    <button
      ref={ref}
      type="button"
      className={cn(
        buttonVariants({ className, variant }),
        'py-2 pl-3 pr-2 gap-2',
      )}
      disabled={disabled}
      {...props}
    >
      {children}
      {!hideArrow && (
        <ArrowDropDownIcon
          size="small"
          className={cn(
            iconLayoutClasses,
            iconSpacingClasses,
            disabled ? iconDisabledClasses : iconColorClasses,
          )}
        />
      )}
    </button>
  ),
);

DropdownMenuTriggerButton.displayName = 'DropdownMenuTriggerButton';

export { DropdownMenuTriggerButton, type DropdownMenuTriggerButtonProps };
