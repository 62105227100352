import React, { useState, useEffect, useRef } from 'react';

import cn from '@pxui/lib/utils';
import * as RadixSlider from '@radix-ui/react-slider';

export interface RangeSliderProps {
  debounceTime?: number;
  defaultRange?: [number, number];
  max: number;
  min?: number;
  onChange: (values: [number, number]) => void;
}

export const RangeSlider: React.FC<RangeSliderProps> = ({
  min = 1,
  max,
  defaultRange = [1, 1],
  onChange,
  debounceTime,
}) => {
  const [range, setRange] = useState<[number, number]>(
    defaultRange || [min, max],
  );

  const timeoutRef = useRef<number | undefined>(undefined);

  // Handle debouncing for the onChange callback
  useEffect(() => {
    if (debounceTime !== undefined) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = window.setTimeout(() => {
        onChange(range); // Trigger onChange with the final value after debounce
      }, debounceTime);

      return () => {
        clearTimeout(timeoutRef.current);
      };
    }
    // If no debounceTime is provided, trigger onChange immediately
    onChange(range);
  }, [range, debounceTime, onChange]);

  useEffect(() => {
    setRange([min, max]);
  }, [min, max]);

  const handleValueChange = (value: [number, number]) => {
    setRange(value); // Update range state immediately
  };

  // Min value display with fixed width for 3-digit numbers
  const textStyle =
    'w-[40px] text-center text-[13px] leading-[16px] font-normal text-left text-on-surface-subtle';

  const thumbStyle = cn(
    'absolute w-[20px] h-[20px] bg-primary-container rounded-full',
    'elevation-01 cursor-pointer',
  );

  return (
    <div className="w-full flex justify-between gap-1 items-center self-center h-10">
      <span className={textStyle}>{range[0]}</span>

      <div className="relative w-full h-[6px] rounded-full bg-surface-container">
        <RadixSlider.Root
          className="relative flex items-center self-center w-full h-[6px]"
          min={min}
          max={max}
          value={range}
          onValueChange={handleValueChange}
          step={1}
        >
          <RadixSlider.Track className="relative w-full h-[6px] bg-surface-container rounded-full">
            <RadixSlider.Range className="absolute h-full bg-primary-container rounded-full" />
          </RadixSlider.Track>

          <RadixSlider.Thumb
            className={cn(thumbStyle, 'top-[-10px] left-[-10px]')}
          />

          <RadixSlider.Thumb
            className={cn(thumbStyle, 'top-[-10px] right-[-10px]')}
          />
        </RadixSlider.Root>
      </div>

      <span className={textStyle}>{range[1]}</span>
    </div>
  );
};

export default RangeSlider;
