import { type TdHTMLAttributes, forwardRef } from 'react';

import { cn } from '@pxui/lib/utils';

const layoutClasses = 'label-1 align-middle';
const spacingClasses = 'px-4 py-3.5';
const colorClasses = 'text-primary';

export const TableCell = forwardRef<
  HTMLTableCellElement,
  TdHTMLAttributes<HTMLTableCellElement>
>(({ className, children, ...props }, ref) => (
  <td
    ref={ref}
    className={cn(layoutClasses, spacingClasses, colorClasses, className)}
    {...props}
  >
    <div className="flex gap-1.5 items-center">{children}</div>
  </td>
));

TableCell.displayName = 'TableCell';

export default TableCell;
