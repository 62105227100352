import { useEffect, type FC, type ReactNode } from 'react';

import {
  CloseSmallIcon,
  Button,
  GeometryInspectorContainer,
  GeometryInspectorHeader,
  ExpandContentIcon,
} from '@pxui/components/ui';

import LoadingIndicator from '@components/vtk-viewer-wasm/components/LoadingIndicator/LoadingIndicator';
import useMeshVisualiser from '@components/vtk-viewer-wasm/hooks/useMeshVisualiser';

interface GeometryInspectorPanelProps {
  buttons: ReactNode;
  children?: ReactNode;
  filename?: string;
  onClose?: () => void;
  onExpand?: () => void;
  open?: boolean;
  title?: string;
  url?: string;
}

const GeometryInspectorPanel: FC<GeometryInspectorPanelProps> = ({
  buttons,
  children,
  filename,
  onClose,
  onExpand,
  open,
  title,
  url,
}) => {
  const { addMesh, canvasRef, initialized, isLoading } = useMeshVisualiser({});

  useEffect(() => {
    if (initialized && filename && url) {
      addMesh(filename, url);
    }
  }, [addMesh, filename, initialized, url]);

  return (
    <GeometryInspectorContainer open={open}>
      <GeometryInspectorHeader>
        <Button
          layout="iconOnly"
          variant="ghost"
          icon={CloseSmallIcon}
          onClick={onClose}
        />
        <span className="title-2 text-on-surface flex-grow">{title}</span>

        {buttons}
      </GeometryInspectorHeader>

      <div className="relative w-full h-[624px] bg-surface-container-low rounded-lg flex-shrink-0">
        <div className="absolute right-4 top-4 z-10 w-8 h-8 p-1 bg-surface-container-high rounded">
          <Button
            className="w-6 h-6 p-0 bg-surface-container-high rounded-sm"
            disabled
            icon={ExpandContentIcon}
            layout="iconOnly"
            onClick={onExpand}
            variant="ghost"
          />
        </div>

        {isLoading && (
          <div className="w-full flex justify-center">
            <LoadingIndicator />
          </div>
        )}
        {!url && !isLoading && initialized && (
          <div className="w-full flex justify-center">
            <span className="text-primary z-10 absolute top-[50%]">
              {`Geometry ${!filename ? 'and filename' : ''} for this candidate is not available`}
            </span>
          </div>
        )}
        <div id="canvas-wrapper" className="rounded-lg overflow-hidden">
          <canvas id="canvas" ref={canvasRef} />
        </div>
      </div>

      {children}
    </GeometryInspectorContainer>
  );
};

export { GeometryInspectorPanel, type GeometryInspectorPanelProps };
