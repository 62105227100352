import { ReactNode } from 'react';

import { Toast, ToastAction } from '.';

export type ToastProps = React.ComponentPropsWithoutRef<typeof Toast>;

export type ToastActionElement = React.ReactElement<typeof ToastAction>;

export enum ToastType {
  Default = 'default',
  Progress = 'progress',
}

export type ToastDetails = {
  elapsed: number;
  error: boolean;
  indicatorLabel: string;
  progress: number;
};

export type ToasterToast = ToastProps & {
  action?: ToastActionElement;
  description?: string;
  details?: ToastDetails;
  id: string;
  onCancel?: (toastId: string) => void;
  title?: ReactNode;
  toastType?: ToastType;
  withXButton?: boolean;
};

export type ToastResult = {
  dismiss: () => void;
  id: string;
  update: (updateProps: ToasterToast) => void;
};
