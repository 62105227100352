import type { FC, HTMLAttributes, ReactNode } from 'react';

import { cn } from '@pxui/lib/utils';

import { textEllipsis } from '@constants/css';

interface ListItemProps extends Omit<HTMLAttributes<HTMLDivElement>, 'prefix'> {
  label: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
}

const buttonLayoutClasses = 'flex justify-start items-end rounded';
const buttonSpacingClasses = 'p-2.5 gap-2';
const buttonInteractionClasses = 'hover:state-hover';

const labelLayoutClasses = 'label-1';
const labelColorClasses = 'text-primary';

const ListItem: FC<ListItemProps> = ({
  prefix,
  suffix,
  label,
  className,
  ...props
}) => (
  <div
    className={cn(
      buttonLayoutClasses,
      buttonSpacingClasses,
      buttonInteractionClasses,
      className,
      'cursor-pointer',
    )}
    {...props}
  >
    {prefix}

    <span
      className={cn(labelLayoutClasses, labelColorClasses, textEllipsis)}
      title={label}
    >
      {label}
    </span>

    {suffix && <div className="ml-auto flex flex-row">{suffix}</div>}
  </div>
);

export { ListItem, type ListItemProps };
