import { type InputHTMLAttributes, forwardRef } from 'react';

import { ErrorText, HelperText, Label } from '@pxui/components/ui/textInput';
import { cn } from '@pxui/lib/utils';

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  errorText?: string;
  helperText?: string;
  id: string;
  labelText?: string;
  wrapperClassName?: string;
}

const layoutClasses = 'peer flex rounded outline-0';
const spacingClasses = 'pl-3 pr-1.5 py-2';
const placeholderClasses = 'placeholder:label-1 placeholder:text-placeholder';
const inputStateClasses =
  'focus-within:state-focus disabled:text-disabled disabled:placeholder:text-disabled invalid:border-auxiliary-error';

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      className,
      type,
      id,
      labelText,
      helperText,
      errorText,
      disabled,
      wrapperClassName,
      ...props
    },
    ref,
  ) => {
    const wrapperClasses = cn('flex flex-col gap-2', wrapperClassName);

    const labelClasses = cn('label-2 text-primary', {
      'text-disabled': disabled,
    });

    const inputClasses = cn(
      'label-1 text-primary w-full elevation-00 bg-field-01 group-[.surface-01]:bg-surface-container-low',
      layoutClasses,
      spacingClasses,
      placeholderClasses,
      inputStateClasses,
      className,
      errorText ? 'border-danger' : '',
    );

    return (
      <div className={wrapperClasses}>
        <Label text={labelText} className={labelClasses} htmlFor={id} />
        <input
          id={id}
          type={type}
          disabled={disabled}
          className={inputClasses}
          ref={ref}
          {...props}
        />
        {!errorText && <HelperText helperText={helperText} />}
        <ErrorText errorText={errorText} />
      </div>
    );
  },
);
TextInput.displayName = 'TextInput';

export { TextInput };
